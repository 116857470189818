import { v4 as uuidv4 } from 'uuid';

// region of interest
class Annotation {
  constructor(data) {
    this.hovered = false;
    this.selected = false;

    const {
      x1,
      y1,
      x2,
      y2,
      id
    } = data;

    this.id = id || uuidv4();

    this.box = {
      startX: x1,
      startY: y1,
      endX: x2,
      endY: y2
    };

    this.renderBox = null;
  }

  updateScreenCoords(percToCanvas) {
    const {
      startX: x1,
      startY: y1,
      endX: x2,
      endY: y2
    } = this.box;

    const { x: startX, y: startY } = percToCanvas(x1, y1);

    const { x: endX, y: endY } = percToCanvas(x2, y2);

    this.renderBox = {
      startX,
      startY,
      endX,
      endY
    };
  }

  drawBox(ctx) {
    if (!this.renderBox) {
      return;
    }

    const { startX, startY, endX, endY } = this.renderBox;

    ctx.rect(
      startX,
      startY,
      endX - startX,
      endY - startY
    );
  }

  fillBox(ctx) {
    if (!this.renderBox) {
      return;
    }

    const { startX, startY, endX, endY } = this.renderBox;

    ctx.rect(
      startX,
      startY,
      endX - startX,
      endY - startY
    );
  }
}

export default Annotation;
