import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle
} from 'react';

import css from './styles.module.css';

import Streamer from './Streamer';

const NUMBER_OF_RETRIES = Infinity;

const zoomInIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none"><path d="M20 20L14.9497 14.9497M14.9497 14.9497C16.2165 13.683 17 11.933 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C11.933 17 13.683 16.2165 14.9497 14.9497ZM7 10H13M10 7V13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>
);

const zoomOutIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none"><path d="M20 20L14.9497 14.9498M14.9497 14.9498C16.2165 13.683 17 11.933 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C11.933 17 13.683 16.2165 14.9497 14.9498ZM7 10H13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>
);

const centerIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none"><path d="M8 4H6C4.89543 4 4 4.89543 4 6V8M8 20H6C4.89543 20 4 19.1046 4 18V16M16 4H18C19.1046 4 20 4.89543 20 6V8M16 20H18C19.1046 20 20 19.1046 20 18V16M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>
);

const fullScreenIcon = (
  <svg height="16px" version="1.1" viewBox="0 0 14 14" width="16px" xmlns="http://www.w3.org/2000/svg" sketch="http://www.bohemiancoding.com/sketch/ns" xlink="http://www.w3.org/1999/xlink"><g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g fill="#fff" id="Core" transform="translate(-215.000000, -257.000000)"><g id="fullscreen" transform="translate(215.000000, 257.000000)"><path d="M2,9 L0,9 L0,14 L5,14 L5,12 L2,12 L2,9 L2,9 Z M0,5 L2,5 L2,2 L5,2 L5,0 L0,0 L0,5 L0,5 Z M12,12 L9,12 L9,14 L14,14 L14,9 L12,9 L12,12 L12,12 Z M9,0 L9,2 L12,2 L12,5 L14,5 L14,0 L9,0 L9,0 Z" id="Shape" /></g></g></g></svg>
);

const NewCanvas = (props) => {
  const {
    ref,
    children,
    hideControl,
    deviceId,
    deviceIP,
    className,
    coverStyle = 'contain',
    videoClassName,
    customEls,
    customElsRight,
    socketClient,
    isDebugView,
    shouldDrawMovement,
    shouldDrawHeadPos,
    showIds,
    highlightId,
    highlights,
    addingAnnotation,
    onClick = () => {},
    onLoaded = () => {},
    onAnnotationCreate = () => {},
    annotations = []
  } = props;

  const [loading, setLoading] = useState(true);

  const [streamer] = useState(new Streamer());
  const imgContainerRef = useRef();
  const videoRef = useRef();
  const canvasRef = useRef();
  const timeoutRef = useRef();

  useImperativeHandle(ref, () => ({
    removeAnnotation(id) {
      if (streamer) {
        streamer.removeAnnotation(id);
      }
    }
  }), []);

  const startStreamer = useCallback((streamerRef) => {
    clearTimeout(timeoutRef.current);

    if (!deviceIP) {
      return false;
    }

    setLoading(true);

    streamerRef.onFatalError = () => {
      if (streamerRef.destroyed) return;

      timeoutRef.current = setTimeout(() => startStreamer(streamerRef), 1000);
    }

    streamerRef.onFragLoaded = () => {
      setLoading(false);
      onLoaded();
    };

    streamerRef.start(
      deviceIP,
      deviceId,
      imgContainerRef.current,
      videoRef.current,
      canvasRef.current,
      annotations
    );
  }, [deviceIP, deviceId, annotations]);

  useEffect(() => {
    const streamerRef = streamer;

    startStreamer(streamerRef);

    return () => {
      streamerRef.stop();
    };
  }, [startStreamer, streamer]);

  useEffect(() => {
    const streamerRef = streamer;
    if (!loading && socketClient && deviceId) {
      streamerRef.startSockets(socketClient, deviceId);
    }

    return () => {
      if (!loading && socketClient) {
        streamerRef.stopSockets(socketClient, deviceId);
      }
    };
  }, [loading, socketClient, deviceId, streamer]);

  useEffect(() => {
    const streamerRef = streamer;
    if (!loading) {
      streamerRef.onClick = (entity) => {
        if (onClick) onClick(entity);
      };

      streamerRef.coverStyle = coverStyle;
      streamerRef.isDebugView = isDebugView;
      streamerRef.shouldDrawMovement = shouldDrawMovement;
      streamerRef.shouldDrawHeadPos = shouldDrawHeadPos;
      streamerRef.showIds = showIds;
      streamerRef.highlightId = highlightId;
      streamerRef.highlights = highlights;
      streamerRef.addingAnnotation = addingAnnotation;
      streamerRef.onAnnotationCreate = onAnnotationCreate;
    }
  }, [
    loading,
    isDebugView,
    shouldDrawMovement,
    shouldDrawHeadPos,
    showIds,
    highlightId,
    highlights,
    onClick,
    coverStyle,
    streamer,
    addingAnnotation,
    onAnnotationCreate
  ]);

  let loaderEl = null;
  if (loading) {
    loaderEl = (
      <div className={css.loaderContainer}>
        <div className={css.loader} />
      </div>
    )
  }

  const controlEl = !hideControl
    ? (
      <div className={css.btnContainer}>
        <div>
          {customEls}
        </div>
        <div>
          {customElsRight}
          <button
            className={css.btn}
            onClick={() => streamer.zoomIn()}
          >
            {zoomInIcon}
          </button>
          <button
            className={css.btn}
            onClick={() => streamer.zoomOut()}
          >
            {zoomOutIcon}
          </button>
          <button
            className={css.btn}
            onClick={() => streamer.center()}
          >
            {centerIcon}
          </button>
          <button
            className={css.btn}
            onClick={() => streamer.toggleFullscreen()}
          >
            {fullScreenIcon}
          </button>
        </div>
      </div>
    )
    : null;

  const videoCanvas = (
      <video
        ref={videoRef}
        className={`${css.viewer} ${videoClassName || ''}`}
        muted
        autoPlay
        playsInline
      />
    );

  const videoContainerCoverCss = coverStyle === 'contain' ? css.contain : '';
  const innerCanvasEl = coverStyle === 'contain'
    ? <canvas ref={canvasRef} className={css.canvas} />
    : null;

  const outerCanvasEl = coverStyle === 'cover'
    ? <canvas ref={canvasRef} className={css.canvas} />
    : null;

  return (
    <div
      className={`${css.imgContainer} ${className || ''}`}
      ref={imgContainerRef}
      tabIndex="0"
    >
      <div className={`${css.videoContainer} ${videoContainerCoverCss}`}>
        {videoCanvas}
        {innerCanvasEl}
      </div>

      {outerCanvasEl}

      {loaderEl}
      {controlEl}

      {children}
    </div>
  );
};

export default NewCanvas;
