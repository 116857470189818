import React, { useContext, useState, useCallback, useEffect } from 'react';
import { APIProvider, Map, useMap, AdvancedMarker } from '@vis.gl/react-google-maps';
import { useNavigate } from 'react-router-dom';

import CameraStackContext from '../../../context/CameraStackContext';

import PenListView from './PenListView';

import AreaMarker from './AreaMarker';
import DeviceMarker from './DeviceMarker';

import css from '../styles.module.scss';

const { REACT_APP_GOOGLE_MAP_KEY } = process.env;

const containerStyle = {
  width: '400px',
  height: '400px',
}

function CustomMap(props) {
  const { children, onLoad } = props;

  const map = useMap();

  useEffect(() => {
    if (!map) return;

    onLoad(map);
  }, [map]);

  return (
    <Map {...props}>
      {children}
    </Map>
  );
}

function PoleMap(props) {
  const { locationId, data, isFetching, performance, stats } = props;

  const { addToStack } = useContext(CameraStackContext);

  const navigate = useNavigate();

  function selectArea(area) {
    const device = area?.devices[0];
    addToStack(area.id, device.id, device.name);

    let url = `/producers/herd-sense?locationId=${locationId}&monitoringAreaId=${area.id}&deviceId=${device.id}`;
    if (performance.length) {
      const filters = performance.join(',');
      url += `&filters=${filters}`;
    }

    navigate(url);
  }

  function selectDevice(areaId, device) {
    addToStack(areaId, device.id, device.name);

    let url = `/producers/herd-sense?locationId=${locationId}&monitoringAreaId=${areaId}&deviceId=${device.id}`;
    if (performance.length) {
      const filters = performance.join(',');
      url += `&filters=${filters}`;
    }

    navigate(url);
  }

  const onMapsLoaded = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    data?.forEach((area) => {
      area.devices.forEach((device) => {
        bounds.extend({
          lat: device.latitude,
          lng: device.longitude
        });
      });
    });

    map.fitBounds(bounds);
  }, [data]);

  if (isFetching) {
    return null;
  }

  const areaMarkers = data
    .map((area) => {
      const latitude = area.devices.length
        ? area.devices.reduce((s, d) => s + d.latitude, 0) / area.devices.length
        : null;

      const longitude = area.devices.length
        ? area.devices.reduce((s, d) => s + d.longitude, 0) / area.devices.length
        : null;

      const areaStats = (stats || {})[area.id] || {
        low_intake: 0,
        high_intake: 0,
        weight_losses: 0,
        weight_gains: 0
      };

      const count = performance.reduce((sum, key) => sum + areaStats[key], 0);

      return (
        <AdvancedMarker
          key={area.id}
          position={{ lat: latitude, lng: longitude }}
        >
          <AreaMarker
            label={area.name}
            onClick={() => selectArea(area)}
            count={count}
          />
        </AdvancedMarker>
      );
    });

  const deviceMarkers = data
    ?.reduce((arr, area) => {
      arr.push(...area.devices);
      return arr;
    }, [])
    .map((device) => (
      <AdvancedMarker
        key={device.id}
        position={{ lat: device.latitude, lng: device.longitude }}
      >
        <DeviceMarker
          online={device.online}
          label={device.name}
          onClick={() => selectDevice(device.monitoring_area_id, device)}
        />
      </AdvancedMarker>
    ));

  return (
    <div className={css.mapView}>
      <PenListView
        locationId={locationId}
        data={data}
        addToStack={addToStack}
        performance={performance}
      />
      <div className={css.mapContainer}>
        <APIProvider apiKey={REACT_APP_GOOGLE_MAP_KEY}>
          <CustomMap
            mapId="dashboard-map"
            style={{width: '100%', height: '100%'}}
            defaultCenter={{lat: 0, lng: 0}}
            defaultZoom={15}
            mapTypeId="satellite"
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            onLoad={onMapsLoaded}
          >
            {deviceMarkers}
            {areaMarkers}
          </CustomMap>
        </APIProvider>
      </div>
    </div>
  );
}

export default PoleMap;
