import React, { useState, useRef, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMutation from '../../hooks/useMutation';

import useQuery from '../../hooks/useQuery';

import ExpandTable from './ExpandTable';
import PenDetails from './PenDetails';
import CattleDetails from './CattleDetails';
import CameraViewGrid from './CameraViewGrid';

import DevModeModal from './DevModeModal';

import useSubscription from '../../hooks/useSubscription';
import useShowIds from '../../hooks/useShowIds';

import Client from '../../apis/Client';

import SocketContext from '../../context/SocketContext';
import CameraStackContext from '../../context/CameraStackContext';

import css from './styles.module.scss';

function PenManager() {
  const [searchParams, setSearchParams] = useSearchParams();

  const socketClient = useContext(SocketContext);
  const { addToStack } = useContext(CameraStackContext);

  const locationId = searchParams.get('locationId');
  const cattleId = searchParams.get('cattleId');
  const monitoringAreaId = searchParams.get('monitoringAreaId');
  const deviceId = searchParams.get('deviceId');

  let searchFilters = searchParams.get('filters');
  searchFilters = searchFilters ? searchFilters.split(',') : [];

  const [isDebugView, setIsDebugView] = useState(false);
  const [areaCattle, setAreaCattle] = useState([]);
  const [selectedCattle, setSelectedCattle] = useState(null);
  const [highlightId, setHighlightId] = useState(null);

  const [showDevModeModal, setShowDevModeModal] = useState(false);

  const [showIds, setShowIds] = useShowIds();

  const [shouldDrawMovement, setShouldDrawMovement] = useState(true);
  const [shouldDrawHeadPos, setShouldDrawHeadPos] = useState(true);

  function updateCattleId(id) {
    const params = { locationId, monitoringAreaId };
    if (deviceId) {
      params.deviceId = deviceId;
    }

    if (searchFilters.length) {
      params.filters = searchFilters.join(',')
    }

    if (!id) {
      setSearchParams(params);
      return;
    }

    if (id === cattleId) {
      return;
    }

    setSearchParams({
      ...params,
      cattleId: id
    });
  }

  function updateAreaId(id, dId) {
    if (id === monitoringAreaId && dId === deviceId) {
      return;
    }

    const params = {
      locationId,
      monitoringAreaId: id
    };

    if (dId) {
      params.deviceId = dId;
    }

    if (searchFilters.length) {
      params.filters = searchFilters.join(',')
    }

    setSearchParams(params);
  }

  function checkFilterBox(name) {
    return (e) => {
      const { checked } = e.target;

      const filters = [...searchFilters];

      const index = filters.findIndex(f => f === name);
      if (checked && index < 0) {
        filters.push(name);
      } else if (!checked && index >= 0) {
        filters.splice(index, 1);
      }

      const params = { locationId, monitoringAreaId };
      if (deviceId) {
        params.deviceId = deviceId;
      }

      if (cattleId) {
        params.cattleId = cattleId;
      }

      if (filters.length) {
        params.filters = filters.join(',');
      }

      setSearchParams(params);
    };
  }

  const { data: areaData, isLoading: isAreaLoading, refetch: refetchAreaData } = useQuery({
    queryKey: ['get_area_data', locationId, monitoringAreaId],
    queryFn: () => Client.get(`/api/v1/producers/locations/${locationId}/monitoring-areas/${monitoringAreaId}`),
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const areaId = data?.id || null;
      const device = data?.devices.find(d => d.id === deviceId);

      updateAreaId(areaId, device?.id);

      if (data) {
        addToStack(areaId, deviceId, device?.name || data.name);
        fetchAreaCattle(data);
      } else {
        setAreaCattle([]);
      }
    }
  });

  const { mutate: fetchAreaCattle, isLoading: isAreaCattleLoading } = useMutation({
    mutationKey: ['get_area_cattle', locationId, monitoringAreaId],
    mutationFn: async (data) => Client.get(`/api/v1/producers/locations/${locationId}/cattle?monitoring_area=${monitoringAreaId}&all=true`),
    onSuccess: (data) => {
      setAreaCattle(data);
    }
  });

  const { isFetching: isSelectedLoading, refetch: refetchSelected } = useQuery({
    queryKey: ['get_cattle_view_details', locationId, cattleId, monitoringAreaId],
    queryFn: () => Client.get(`/api/v1/producers/locations/${locationId}/cattle/${cattleId}?monitoring_area=${monitoringAreaId}`),
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setSelectedCattle(data);
    },
    enabled: !!cattleId && !!monitoringAreaId,
  });

  const { mutate: handleAnnotationCreate } = useMutation({
    mutationFn: (data) => {
        return Client.post(`/api/v1/producers/locations/${locationId}/devices/${deviceId}/annotation`, data);
    },
    onSuccess: () => {
      refetchAreaData();
    }
  });

  const { mutate: deleteAnnotation } = useMutation({
    mutationFn: (data) => {
        return Client.delete(`/api/v1/producers/locations/${locationId}/devices/${deviceId}/annotation`, data);
    },
    onSuccess: () => {
      refetchAreaData();
    }
  });

  function refreshData() {
    fetchAreaCattle();
    refetchSelected();
  }

  function closeSidebar() {
    updateCattleId(null);
    setHighlightId(null);
  }

  function handleDevModeState() {
    setShowDevModeModal(false);
    setIsDebugView(true);
  }

  useSubscription(socketClient, 'area-mug-post', monitoringAreaId, (data) => {
    const isSelected = selectedCattle?.id === data.id;
    if (isSelected) {
      setSelectedCattle({
        ...selectedCattle,
        mug_id: data.mug_id,
        mug: data.mug,
        mug_debug_id: data.mug_debug_id,
        mug_debug: data.mug_debug
      });
    }

    const existingIndex = areaCattle.findIndex(c => c.id === data.id);
    if (existingIndex >= 0) {
      const updated = {
        ...areaCattle[existingIndex],
        mug_id: data.mug_id,
        mug: data.mug,
        mug_debug_id: data.mug_debug_id,
        mug_debug: data.mug_debug
      };

      setAreaCattle([
        ...areaCattle.slice(0, existingIndex),
        updated,
        ...areaCattle.slice(existingIndex + 1)
      ]);
    } else {
      setAreaCattle([...areaCattle, data]);
    }
  });

  const devices = deviceId
    ? areaData?.devices.filter(d => d.id === deviceId)
    : areaData?.devices;

  const device = devices
    ? devices.find(d => d.id === deviceId)
    : null;

  const timezone = areaData?.devices[0]?.timezone || 'UTC';

  const panelEl = cattleId
    ? <CattleDetails
      locationId={locationId}
      areaId={monitoringAreaId}
      cattleId={cattleId}
      data={selectedCattle}
      isLoading={isAreaLoading || isSelectedLoading}
      refetch={refreshData}
      highlightId={highlightId}
      setHighlightId={setHighlightId}
      timezone={timezone}
    />
    : <PenDetails
      isDebugView={isDebugView}
      data={areaData}
      refetch={refetchAreaData}
      locationId={locationId}
      isLoading={isAreaLoading}
      showIds={showIds}
      setShowIds={setShowIds}
      shouldDrawMovement={shouldDrawMovement}
      setShouldDrawMovement={setShouldDrawMovement}
      shouldDrawHeadPos={shouldDrawHeadPos}
      setShouldDrawHeadPos={setShouldDrawHeadPos}
      timezone={timezone}
      highlightFilters={searchFilters}
      checkFilterBox={checkFilterBox}
    />;

  const devModeBtn = isDebugView
    ? (
    <button className='btn btn-outline-primary' onClick={() => setIsDebugView(false)}>Hide Dev mode</button>)
    : (
      <button className='btn btn-outline-primary' onClick={() => setShowDevModeModal(true)}>Dev mode</button>
    );

  const penHeaderEl = (
    <div className={css.header}>
      <h2>{device?.name || areaData?.name}</h2>
      {devModeBtn}
    </div>
  );

  const cattleHeaderEl = !isSelectedLoading && cattleId
    ? (
      <div className={`${css.header} ${css.cattleheader}`}>
        <h2>ID: {selectedCattle?.name || cattleId}</h2>
        <button onClick={closeSidebar}>+</button>
      </div>
    )
    : null;

  const cameraHighlights = areaData
    ? searchFilters.reduce((arr, filter) => {
      arr.push(...(areaData[filter] || []));
      return arr;
    }, [])
    : [];

  return (
    <div className={`${css.container}`}>
      <CameraViewGrid
        devices={devices}
        socketClient={socketClient}
        updateCattleId={updateCattleId}
        showIds={true}
        highlightId={highlightId}
        shouldDrawMovement={shouldDrawMovement}
        shouldDrawHeadPos={shouldDrawHeadPos}
        isDebugView={isDebugView}
        isLoading={isAreaLoading || isAreaCattleLoading}
        selectedId={cattleId}
        areaCattle={areaCattle}
        highlights={cameraHighlights}
        onAnnotationCreate={handleAnnotationCreate}
        onAnnotationDelete={deleteAnnotation}
        annotations={device?.regions_of_interest}
      />

      <div className={css.sidebarContainer}>
        {penHeaderEl}

        {cattleHeaderEl}

        {panelEl}
      </div>

      {showDevModeModal && (
        <DevModeModal
          showModal={showDevModeModal}
          setShowModal={setShowDevModeModal}
          onAccept={handleDevModeState}
        />
      )}
    </div>
  );
}

export default PenManager;
