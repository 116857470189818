import {
  drinkIcon,
  eatIcon,
  idleIcon,
  walkIcon,
  layingIcon,
  trotIcon,
  urinateIcon
} from '../icons';

const SKELETONS = [
  [0, 1],
  [0, 2],
  [1, 2],

  [1, 3],
  [2, 4],

  [5, 7],
  [6, 8],

  [7, 9],
  [8, 10],

  [11, 13],
  [12, 14],

  [13, 15],
  [14, 16],
];

const POINT_RADIUS = 4;

class Cattle {
  constructor(pose) {
    const {
      bones,
      bbox,
      gId,
      trackId,
      behavior,
      weight,
      avgWeight
    } = pose;

    this.hovered = false;
    this.selected = false;
    this.gId = gId;
    this.id = gId;
    this.trackId = trackId;
    this.behavior = behavior;
    this.weight = weight;
    this.avgWeight = avgWeight;

    this.bones = bones
      ? SKELETONS.reduce((arr, skeleton) => {
        const [startX, startY, conf1] = bones[skeleton[0]];
        const [endX, endY, conf2] = bones[skeleton[1]];

        const confidence = (conf1 + conf2) / 2;

        if (startX && startY) {
          if (endX && endY) {
            arr.push({
              startX,
              startY,
              endX,
              endY,
              confidence
            });
          } else {
            arr.push({
              startX,
              startY,
              confidence: conf1
            });
          }
        } else if (endX && endY) {
          arr.push({
            endX,
            endY,
            confidence: conf2
          });
        }

        return arr;
      }, [])
      : [];

    this.box = null;
    if (bbox) {
      const [startX, startY, endX, endY] = bbox;

      this.box = {
        startX,
        startY,
        endX,
        endY
      }
    }

    // use only for rendering
    this.renderBones = [];
    this.renderBox = null;
  }

  updateScreenCoords(frameCoordToCanvas) {
    this.renderBones = this.bones.map((bone) => {
      const {
        startX: x1,
        startY: y1,
        endX: x2,
        endY: y2,
        confidence
      } = bone;

      const { x: startX, y: startY } = frameCoordToCanvas(x1, y1);

      const { x: endX, y: endY } = frameCoordToCanvas(x2, y2);

      return {
        startX,
        startY,
        endX,
        endY,
        confidence
      };
    });

    const {
      startX: x1,
      startY: y1,
      endX: x2,
      endY: y2
    } = this.box;

    const { x: startX, y: startY } = frameCoordToCanvas(x1, y1);

    const { x: endX, y: endY } = frameCoordToCanvas(x2, y2);

    this.renderBox = {
      startX,
      startY,
      endX,
      endY
    };
  }

  drawPose(ctx, confidence) {
    this.renderBones.forEach((bone) => {
      const { startX, startY, endX, endY, confidence: boneConfidence } = bone;

      if (boneConfidence < confidence) {
        return;
      }

      if (!isNaN(startX) && !isNaN(startY)) {
        if (!isNaN(endX) && !isNaN(endY)) {
          ctx.moveTo(startX, startY);
          ctx.lineTo(endX, endY);
        } else {
          ctx.moveTo(startX + POINT_RADIUS, startY);
          ctx.arc(startX, startY, POINT_RADIUS, 0, Math.PI * 2);
        }
      } else if (!isNaN(endX) && !isNaN(endY)) {
        ctx.moveTo(startX + POINT_RADIUS, startY);
          ctx.arc(startX, startY, POINT_RADIUS, 0, Math.PI * 2);
      }
    });
  }

  drawBox(ctx) {
    if (!this.renderBox) {
      return;
    }

    const { startX, startY, endX, endY } = this.renderBox;

    ctx.rect(
      startX,
      startY,
      endX - startX,
      endY - startY
    );
  }

  fillBox(ctx) {
    if (!this.renderBox) {
      return;
    }

    const { startX, startY, endX, endY } = this.renderBox;

    ctx.rect(
      startX,
      startY,
      endX - startX,
      endY - startY
    );
  }

  drawActiveBox(ctx, borderRadius) {
    if (!this.renderBox) {
      return;
    }

    const { startX, startY, endX, endY } = this.renderBox;

    ctx.roundRect(
      startX,
      startY,
      endX - startX,
      endY - startY,
      borderRadius
    );
  }

  drawHeader(ctx, options = {}) {
    const boxPaddingH = 4;
    const boxPaddingV = 2;
    const fontHeight = 16;

    const {
      renderBox,
      gId,
      trackId,
      behavior,
      weight,
      avgWeight
    } = this;

    const {
      shouldDrawPoses,
      shouldUseCurrentWeight,
      shouldDrawGIds,
      canDrawIcons,
      shouldDrawMovement
    } = options;

    const [
      eat,
      drink,
      laying,
      idle,
      urinate,
      trot,
      run,
      walk,
    ] = (behavior || []);

    const { startX, startY } = renderBox;

    const displayWeight = shouldUseCurrentWeight
      ? (weight || '').toString()
      : (avgWeight || '').toString();

    const idLabel = shouldDrawPoses ? `t:${trackId},g:${gId}` : gId;
    const label = displayWeight ? `${idLabel}, ${displayWeight} lbs` : `${idLabel}`;

    const strLength = shouldDrawGIds ? ctx.measureText(label).width : 0;

    const behaviorToIcon = [
      { behavior: eat, icon: eatIcon },
      { behavior: drink, icon: drinkIcon },
      { behavior: laying, icon: layingIcon },
      { behavior: idle, icon: idleIcon },
      { behavior: urinate, icon: urinateIcon },
      { behavior: trot, icon: trotIcon },
      { behavior: walk, icon: walkIcon }
    ];

    const icons = [];
    if (canDrawIcons && shouldDrawMovement) {
      behaviorToIcon.forEach(({ behavior, icon }) => {
        if (behavior) {
          icons.push(icon);
        }
      });
    }

    if (!strLength && !icons.length) {
      return;
    }

    const textWidth = strLength ? strLength + (2 * boxPaddingH) : 0;
    const textPadding = strLength ? (2 * boxPaddingH) : 0;

    const boxHeight = fontHeight + (2 * boxPaddingV);
    const boxWidth = strLength + textPadding + (icons.length * (boxHeight + boxPaddingH));

    ctx.beginPath();

    ctx.rect(
      startX,
      startY,
      boxWidth,
      boxHeight
    );

    ctx.fillStyle = '#0007';

    ctx.fill();

    if (strLength) {
      const color = 'white';
      ctx.fillStyle = color;
      ctx.textAlign = 'left';
      ctx.fillText(label, startX + boxPaddingH, startY + boxPaddingV + 14);
    }

    icons.forEach((icon, i) => {
      const dx = startX + textWidth + boxPaddingH + (i * (boxHeight + boxPaddingH));
      const dy = startY;

      ctx.drawImage(icon, dx, dy, boxHeight, boxHeight);
    });
  }
}

export default Cattle;
