import React, { useState } from 'react';

import HeaderPerformance from './HeaderPerformance';

import css from './styles.module.scss';

function Header(props) {
  return (
      <div className={`${css.headerInfo} ${css.map}`}>
        <HeaderPerformance {...props} />
      </div>
  );
}

export default Header;
