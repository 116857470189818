import Cattle from './Cattle';
import Annotation from './Annotation';

class Entities {
  constructor() {
    this.entities = [];
    this.entity = null;
    this.selected = null;
    this.hovered = null;
  }

  clearCattle() {
    this.entities = this.entities.filter(e => !(e instanceof Cattle));
  }

  clearAnnotations() {
    this.entities = this.entities.filter(e => !(e instanceof Annotation));
  }

  clear() {
    this.entities = [];
  }

  registerCattle(payload) {
    const arr = (payload?.instances || []).map((pose) => {
      return new Cattle(pose);
    });

    this.entities.push(...arr);
  }

  registerAnnotations(data) {
    const arr = data.map((annotation) => {
      return new Annotation(annotation)
    });

    this.entities.push(...arr);
  }

  addAnnotation(data) {
    this.entities.push(data instanceof Annotation ? data : new Annotation(data));
  }

  removeAnnotation(id) {
    this.entities = this.entities.filter(e => e.id !== id);
  }
}

export default Entities;
