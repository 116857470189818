class Screen {
  constructor(streamer) {
    this.streamer = streamer;

    this.zoom = this.minZoom;
    this.offsetX = 0;
    this.offsetY = 0;
    this.transform(this.zoom, this.offsetX, this.offsetY);
    this._frameCoordToCanvas = () => ({ x: 0, y: 0 });
  }

  get minZoom() {
    const {
      clientWidth: containerWidth,
      clientHeight: containerHeight
    } = this.streamer.container;

    const {
      clientWidth,
      clientHeight
    } = this.streamer.videoSrc;

    return this.streamer.coverStyle === 'contain'
      ? 1
      : Math.max(containerWidth / clientWidth, containerHeight / clientHeight);
  }

  isFullscreen() {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    );
  }

  enterFullscreen() {
    if (this.streamer.container.requestFullscreen) {
      this.streamer.container.requestFullscreen();
    } else if (this.streamer.container.webkitRequestFullscreen) {
      /* Safari */
      this.streamer.container.webkitRequestFullscreen();
    } else if (this.streamer.container.msRequestFullscreen) {
      /* IE11 */
      this.streamer.container.msRequestFullscreen();
    }
  }

  exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  toggleFullscreen() {
    if (this.isFullscreen()) {
      this.exitFullscreen();
    } else {
      this.enterFullscreen();
    }
  }

  transform(zoom, offsetX, offsetY) {
    this.streamer.videoSrc.style.transform = `translate(${offsetX}px, ${offsetY}px) scale(${zoom})`;
  }

  updateView(zoom, offsetX, offsetY) {
    zoom = zoom ?? this.zoom;
    offsetX = offsetX ?? this.offsetX;
    offsetY = offsetY ?? this.offsetY;

    const { offsetMin, offsetMax } = this.offsetBounds(zoom);

    let newOffsetX = Math.min(offsetX, offsetMax.x);
    newOffsetX = Math.max(newOffsetX, offsetMin.x);
    let newOffsetY = Math.min(offsetY, offsetMax.y);
    newOffsetY = Math.max(newOffsetY, offsetMin.y);

    const newZoom = Math.max(zoom, this.minZoom);

    this.offsetX = newOffsetX;
    this.offsetY = newOffsetY;
    this.zoom = newZoom;

    this.transform(newZoom, newOffsetX, newOffsetY);
  }

  zoomIn() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom * 1.3333333333);
  }

  zoomOut() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom * 0.75);
  }

  pan(deltaX, deltaY) {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom, this.offsetX + deltaX, this.offsetY + deltaY);
  }

  panUp() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom, this.offsetX, this.offsetY + 20);
  }

  panDown() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom, this.offsetX, this.offsetY - 20);
  }

  panLeft() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom, this.offsetX + 20);
  }

  panRight() {
    this.streamer.trackActiveId = false;
    this.updateView(this.zoom, this.offsetX - 20);
  }

  center() {
    this.streamer.trackActiveId = false;
    this.updateView(1, 0, 0);
  }

  offsetBounds(zoom) {
    zoom = Math.max(zoom, 1);

    const {
      clientWidth,
      clientHeight
    } = this.streamer.canvas;

    const {
      clientWidth: videoWidth,
      clientHeight: videoHeight
    } = this.streamer.videoSrc;

    const canvasWidth = zoom * videoWidth;
    const canvasHeight = zoom * videoHeight;

    let x = (canvasWidth - clientWidth) / 2;
    let y = (canvasHeight - clientHeight) / 2;

    if (canvasWidth <= clientWidth) {
      x = 0;
    }

    if (canvasHeight <= clientHeight) {
      y = 0;
    }

    return {
      offsetMin: { x: -x, y: -y },
      offsetMax: { x, y },
    };
  }

  frameCoordToCanvas(frame) {
    if (!frame?.payload) {
      return this._frameCoordToCanvas;
    }

    const { clientWidth, clientHeight } = this.streamer.videoSrc;
    const { frameWidth, frameHeight } = frame.payload;
    const {
      clientWidth: canvasWidth,
      clientHeight: canvasHeight
    } = this.streamer.canvas;

    const fctcFn = (x, y) => {
      // convert to percent of screen
      const frameX = x / frameWidth;
      const frameY = y / frameHeight;

      // change center of screen to 0, 0
      const relX = (frameX - 0.5) * clientWidth * this.zoom;
      const relY = (frameY - 0.5) * clientHeight * this.zoom;

      // scale to screen size and add offsets
      const cameraX = relX + (clientWidth / 2) + this.offsetX;
      const cameraY = relY + (clientHeight / 2) + this.offsetY;

      // adjust to canvas
      const canvasOffsetX = (clientWidth - canvasWidth) / 2;
      const canvasOffsetY = (clientHeight - canvasHeight) / 2;

      return { x: cameraX - canvasOffsetX, y: cameraY + canvasOffsetY };
    }

    this._frameCoordToCanvas = fctcFn;

    return fctcFn;
  }

  percToCanvas() {
    return this.frameCoordToCanvas({ payload: { frameWidth: 100, frameHeight: 100 } });
  }

  zoomOnActiveId(payload) {
    if (!this.streamer.trackActiveId || !payload) {
      return;
    }

    const trackingBox = payload.instances.find(pose => pose.gId?.toString() === this.streamer.activeId);
    if (!trackingBox) {
      return;
    }

    const { bbox } = trackingBox;

    let start_x = bbox[0];
    let start_y = bbox[1];
    let end_x = bbox[2];
    let end_y = bbox[3];

    const { frameWidth, frameHeight } = payload;

    const boxWidth = end_x - start_x;
    const boxHeight = end_y - start_y;
    const percentWidth = frameWidth / boxWidth;
    const percentHeight = frameHeight / boxHeight;
    const minPercent = Math.min(percentWidth, percentHeight);

    const percentPadding = 0.45; // don't zoom in all the way
    const percentZoom = minPercent * percentPadding;

    this.zoom = percentZoom;
    this.offsetX = 0;
    this.offsetY = 0;

    const frameCoordToCanvasFn = this.frameCoordToCanvas({ payload });

    const offsetX = ((Math.max(boxWidth, boxHeight) / percentPadding) - boxWidth) / 2;
    const offsetY = ((Math.max(boxWidth, boxHeight) / percentPadding) - boxHeight) / 2;

    const { x, y } = frameCoordToCanvasFn(
      start_x - offsetX,
      start_y - offsetY
    );

    this.updateView(percentZoom, -x, -y);
  }

  snapshot(label = 'snapshot', overlay = false) {
    if (this.streamer.destroyed) {
      return;
    }

    const { clientWidth, clientHeight } = this.streamer.videoSrc;

    const snapshotCanvas = document.createElement('canvas');
    snapshotCanvas.width = clientWidth;
    snapshotCanvas.height = clientHeight;
    const ctx = snapshotCanvas.getContext('2d');

    ctx.drawImage(this.streamer.videoSrc, 0, 0, clientWidth, clientHeight);

    if (overlay) {
      const frameCoordToCanvasFn = this.frameCoordToCanvas(
        this.streamer.lastFrame,
        this.zoom,
        this.offsetX,
        this.offsetY
      );

      const payload = this.streamer.lastFrame?.payload;

      this.streamer.renderer.render(
        snapshotCanvas,
        payload,
        this.streamer.frameTimestamp,
        this.streamer.framePrevTimestamp,
        frameCoordToCanvasFn
      );
    }

    const link = document.createElement('a');
    link.download = overlay
      ? `${label}_overlay_${new Date().toISOString()}.png`
      : `${label}_${new Date().toISOString()}.png`;
    link.href = snapshotCanvas.toDataURL();
    link.click();
  }
}

export default Screen;
