import React, { useState } from 'react';

import css from './styles.module.scss';

function Header(props) {
  const { location, data } = props;

  const totalHead = data?.reduce((sum, area) => sum + area.total_cattle, 0);

  return (
    <div className={css.title}>
      <h1>{location?.name}</h1>
      <span className={css.totalHead}>Total Head: {totalHead?.toLocaleString()}</span>
    </div>
  );
}

export default Header;
