import React, { useState } from 'react';
import moment from 'moment-timezone';

import CustomSlider from '../../components/forms/CustomSlider';
import Loader from '../../components/Loader';
import ErrorBox from '../../components/ErrorBox';

import RoundNoteModal from './RoundNoteModal';
import PenCattleGraph from './PenCattleGraph';
import BunkGraph from './BunkGraph';

import css from './styles.module.scss';
import IconCarat from '../../icons/Carat';
import IconPencil from '../../icons/Pencil';
import IconGear from '../../icons/Gear';

function Details(props) {
  const {
    isDebugView,
    showIds,
    setShowIds,
    shouldDrawMovement,
    setShouldDrawMovement,
    shouldDrawHeadPos,
    setShouldDrawHeadPos,
    locationId,
    data,
    refetch,
    isLoading,
    isError,
    error,
    timezone,
    highlightFilters,
    checkFilterBox
  } = props;

  const [showRoundNoteModal, setShowRoundNoteModal] = useState(false);

  const [selectedNote, setSelectedNote] = useState(null);

  function createNewRoundNote() {
    setSelectedNote(null);
    setShowRoundNoteModal(true);
  }

  const debugEls = isDebugView
    ? <>
      <li className={css.toolContainer}>
        <CustomSlider
          label="Movement Tracking"
          value={shouldDrawMovement}
          onChange={(val) => setShouldDrawMovement(!shouldDrawMovement)}
        />
      </li>
      <li className={css.toolContainer}>
        <CustomSlider
          label="Head Posture"
          value={shouldDrawHeadPos}
          onChange={(val) => setShouldDrawHeadPos(!shouldDrawHeadPos)}
        />
      </li>
    </>
    : null;

  const round_note = data?.round_note;

  const yesterday = moment().tz(timezone).startOf('day').subtract(1, 'days');
  const fifteenDaysAgo = moment().tz(timezone).startOf('day').subtract(15, 'days');

  const weightData = (data?.weight_metrics || []).map((m) => ({
    ...m,
    data: (m.data || 0).toFixed(0)
  }));

  const weightAvg = data?.weight_metrics?.length
    ? data.weight_metrics.reduce((s, m) => s + m.data, 0)
      / data.weight_metrics.length
    : null;

  let roundNoteEl = null;
  if (isError) {
    roundNoteEl = <ErrorBox show={isError} error={error.message} />
  } else {
    let first_name = null;
    let last_name = null;

    if (round_note) {
      const user = round_note.created_by;
      first_name = user.first_name;
      last_name = user.last_name;
    }

    roundNoteEl = round_note
      ? (
        <div className={css.note}>
          {/* <div> */}
          <p className={css.name}>{first_name} {last_name}</p>
          <p>{moment.utc(round_note.created_at).local().format('M/D/YY, hh:mm A')}</p>
          {/* </div> */}
          <p className={css.n}>{round_note.description}</p>
        </div>
      )
      : (
        <div className={css.note}>
          <p><strong>No note</strong></p>
        </div>
      );
  }

  return (
    <>
      <Loader loading={isLoading}>
        <div className={css.content}>
          <div className={css.sec}>
            <PenCattleGraph
              header="Pen Weight"
              timeLabel="Last two weeks"
              graphLabel="lbs"
              valueKey="data"
              // avg={weightAvg}
              data={weightData}
              startDate={fifteenDaysAgo}
              endDate={yesterday}
            />
          </div>

          <div className={css.sec}>
            <BunkGraph
              header="Bunk Levels"
              data={data?.bunk_metrics}
            />
          </div>

          {/*<div id={css.ai} className={css.sec}>
            <h3>
              <IconGear color='#FFFFFF' />
              <span>AI Suggestions</span>
            </h3>
            <ul>
              <li className={css.toolContainer}>
                <CustomSlider
                  label="Show IDs"
                  value={showIds}
                  onChange={(val) => setShowIds(!showIds)}
                />
              </li>
              {debugEls}
            </ul>
          </div>*/}

          <div id={css.ai} className={css.sec}>
            <h3>
              <IconGear color='#FFFFFF' />
              <span>Filters</span>
            </h3>
            <ul>
              <li className={css.toolContainer}>
                <label>Lameness Category 1 <span>({data?.category_1?.length || 0})</span></label>
                <input
                  type="checkbox"
                  name="category_1"
                  checked={highlightFilters.includes('category_1')}
                  onChange={checkFilterBox('category_1')}
                />
              </li>
              <li className={css.toolContainer}>
                <label>Lameness Category 2 <span>({data?.category_2?.length || 0})</span></label>
                <input
                  type="checkbox"
                  name="category_2"
                  checked={highlightFilters.includes('category_2')}
                  onChange={checkFilterBox('category_2')}
                />
              </li>
              <li className={css.toolContainer}>
                <label>Lameness Category 3 <span>({data?.category_3?.length || 0})</span></label>
                <input
                  type="checkbox"
                  name="category_3"
                  checked={highlightFilters.includes('category_3')}
                  onChange={checkFilterBox('category_3')}
                />
              </li>

              <li className={css.toolContainer}>
                <label>Show Poor Gain <span>({data?.weight_losses?.length || 0})</span></label>
                <input
                  type="checkbox"
                  name="weight_losses"
                  checked={highlightFilters.includes('weight_losses')}
                  onChange={checkFilterBox('weight_losses')}
                />
              </li>
              <li className={css.toolContainer}>
                <label>Show Great Gain <span>({data?.weight_gains?.length || 0})</span></label>
                <input
                  type="checkbox"
                  name="weight_gains"
                  checked={highlightFilters.includes('weight_gains')}
                  onChange={checkFilterBox('weight_gains')}
                />
              </li>
              <li className={css.toolContainer}>
                <label>Show Low Intake <span>({data?.low_intake?.length || 0})</span></label>
                <input
                  type="checkbox"
                  name="low_intake"
                  checked={highlightFilters.includes('low_intake')}
                  onChange={checkFilterBox('low_intake')}
                />
              </li>
              <li className={css.toolContainer}>
                <label>Show High Intake <span>({data?.high_intake?.length || 0})</span></label>
                <input
                  type="checkbox"
                  name="high_intake"
                  checked={highlightFilters.includes('high_intake')}
                  onChange={checkFilterBox('high_intake')}
                />
              </li>
            </ul>
          </div>

          <div className={css.sec}>
            <h3>
              <IconPencil color='#ffffff' />
              <span>Pen Rider Notes</span>
            </h3>
            {roundNoteEl}
            <button
              className="btn btn-outline-primary"
              onClick={createNewRoundNote}
            >
              Add Note
            </button>
          </div>
        </div>
      </Loader>
      {/*<div className={css.actions}>
        <button
          className="btn btn-primary"
          onClick={prevDevice}
          disabled={isDevicesLoading || noNextCamera}
        >
          <IconCarat color='#ffffff' />
          Prev Pen (Camera)
        </button>
        <button
          className="btn btn-primary"
          onClick={nextDevice}
          disabled={isDevicesLoading || noNextCamera}
        >
          Next Pen (Camera)
          <IconCarat color='#ffffff' dir='right' />
        </button>
      </div>*/}

      {showRoundNoteModal && (
        <RoundNoteModal
          showModal={showRoundNoteModal}
          setShowModal={setShowRoundNoteModal}
          selected={selectedNote}
          areaId={data?.id}
          locationId={locationId}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default Details;
