import Annotation from '../Entities/Annotation';
import Cattle from '../Entities/Cattle';
import AnnotationRenderer from './AnnotationRenderer';
import CattleRenderer from './CattleRenderer';

const POINT_RADIUS = 4;

class Renderer {
  constructor(streamer) {
    this.streamer = streamer;
  }

  clear(canvas) {
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  render(canvas, timestamp, prevTimestamp, frameCoordToCanvas, percToCanvas) {
    const ctx = canvas.getContext('2d');

    // draw lines

    const {clientWidth, clientHeight} = this.streamer.canvas;

    // find padding so that canvas fits images 'object-fit: contain;'

    // resize canvas
    canvas.width = clientWidth;
    canvas.height = clientHeight;

    this.renderCattle(ctx, this.streamer, frameCoordToCanvas);

    if (this.streamer.shouldDrawAnnotations) {
      this.renderAnnotations(ctx, this.streamer, percToCanvas);
    }

    if (this.streamer.shouldDrawTimestamp) {
      this.drawFrameDeltaInSecs(
        ctx,
        clientWidth,
        clientHeight,
        timestamp,
        prevTimestamp
      );
    }
  }

  renderCattle(ctx, streamer, frameCoordToCanvas) {
    const { entities, entity } = this.streamer.entities;
    const cattle = [...entities, entity].filter(e => e instanceof Cattle);
    cattle.forEach((head) => {
      head.updateScreenCoords(frameCoordToCanvas);
    });
    CattleRenderer.render(ctx, streamer, cattle);
  }

  renderAnnotations(ctx, streamer, percToCanvas) {
    const { entities, entity } = this.streamer.entities;
    const annotations = [...entities, entity].filter(e => e instanceof Annotation);
    annotations.forEach((a) => {
      a.updateScreenCoords(percToCanvas);
    });
    AnnotationRenderer.render(ctx, streamer, annotations);
  }

  stop(canvas) {
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  drawFrameDeltaInSecs(ctx, clientWidth, clientHeight, timestamp, prevTimestamp) {
    if (!timestamp || !prevTimestamp) {
      return;
    }

    const boxPaddingH = 4;
    const boxPaddingV = 2;

    const fontHeight = 16;

    ctx.beginPath();

    ctx.font = "12px sans-serif";

    const delta = timestamp - prevTimestamp;

    const deltaStr = `${(delta / 1000).toFixed(2)}s`;
    const strLength = ctx.measureText(deltaStr).width;

    const boxWidth = strLength + (2 * boxPaddingH);

    ctx.rect(
      clientWidth - boxWidth,
      0,
      boxWidth,
      fontHeight + (2 * boxPaddingV)
    );

    ctx.fillStyle = '#000a';
    ctx.fill();

    ctx.fillStyle = 'white';
    ctx.textAlign = 'right';
    ctx.fillText(deltaStr, clientWidth - boxPaddingH, fontHeight);
  }
}

export default Renderer;
