class CattleRenderer {
  static render(ctx, streamer, cattle) {
    const {
      activeId,
      confidence,
      highlights,
      shouldDrawPoses,
      shouldDrawBoxes,
      _addingAnnotation
    } = streamer;

    if (_addingAnnotation) {
      // don't render cattle while annotating
      return;
    }

    if (shouldDrawPoses) {
      this.drawPoses(ctx, cattle, confidence);
    }

    if (shouldDrawBoxes) {
      this.drawBoxes(ctx, cattle, highlights);
    }

    this.fillBoxes(ctx, streamer, cattle);
    this.drawActiveBoxes(ctx, cattle, activeId);

    this.drawHeaders(ctx, cattle, streamer);
  }

  static drawBoxes(ctx, cattle, highlights) {
    ctx.beginPath();

    const headsToDraw = highlights.length
      ? cattle.filter(c => highlights.includes(c.gId?.toString()))
      : cattle;

    for (const head of headsToDraw) {
      head.drawBox(ctx);
    }

    ctx.strokeStyle = '#64fe00';
    ctx.lineWidth = 1;
    ctx.stroke();
  }

  static fillBoxes(ctx, streamer, cattle) {
    ctx.beginPath();

    for (const c of cattle) {
      const { gId } = c;

      const hovered = c.id === streamer.entities.hovered?.id;
      const selected = c.id === streamer.entities.selected?.id;

      if (gId && (hovered || selected)) {
        c.fillBox(ctx);
      }
    }

    ctx.fillStyle = '#0003';
    ctx.lineWidth = 1;
    ctx.fill();
  }

  static drawActiveBoxes(ctx, cattle, activeId) {
    ctx.beginPath();

    for (const c of cattle) {
      const { gId } = c;

      if (gId && activeId === gId?.toString()) {
        c.drawActiveBox(ctx, 3);
      }
    }

    ctx.strokeStyle = '#3abdee';
    ctx.lineWidth = 4;
    ctx.stroke();
  }

  static drawPoses(ctx, cattle, confidence) {
    ctx.beginPath();

    cattle.forEach((entity) => {
      entity.drawPose(ctx, confidence);
    });

    ctx.strokeStyle = '#64fe00';
    ctx.lineWidth = 1;
    ctx.stroke();
  }

  static drawHeaders(ctx, cattle, options) {
    // const boxPaddingH = 4;
    // const boxPaddingV = 2;
    // const fontHeight = 16;

    ctx.font = "14px sans-serif";

    for (const c of cattle) {
      c.drawHeader(ctx, options);
    }
  }
}

export default CattleRenderer;
