class EventHandler {
  constructor(streamer) {
    this.streamer = streamer;

    this.lockPanning = false;

    this.mousePos = null;
    this.mouseDown = false;
    this.mouseMove = false;

    this.onMouseDown = () => {};
    this.onDrag = () => {};
    this.onMouseUp = () => {};

    this.listeners = [
      { event: 'mousedown', fn: this.handleMouseDown.bind(this) },
      { event: 'mousemove', fn: this.handleMouseMove.bind(this) },
      { event: 'keydown', fn: this.onKeyDown.bind(this) },
      { event: 'mouseup', fn: this.handleMouseUp.bind(this) }
    ];
  }

  findHitBox(e) {
    const { streamer } = this;

    const boundingRect =  streamer.canvas.getBoundingClientRect();

    const clientX = e.clientX - boundingRect.x;
    const clientY = e.clientY - boundingRect.y;

    const { entities } = streamer.entities;

    for (let entity of entities) {
      const { renderBox } = entity;
      if (!renderBox) {
        continue;
      }

      const { startX, startY, endX, endY } = renderBox;

      const hit = Math.min(startX, endX) <= clientX
        && Math.max(startX, endX) > clientX
        && Math.min(startY, endY) <= clientY
        && Math.max(startY, endY) > clientY;

      if (hit) {
        return entity;
      }
    }
  }

  handleMouseMove(e) {
    const { streamer } = this;

    if (this.mouseDown) {
      this.handleScroll(e);
      return;
    }

    const hitbox = this.findHitBox(e);
    if (!hitbox) {
      streamer.entities.hovered = false;
      streamer.canvas.style.cursor = 'auto';
    } else {
      hitbox.hovered = true;
      streamer.canvas.style.cursor = 'pointer';
    }

    streamer.entities.hovered = hitbox || null;
  }

  createMousePos(e) {
    const { canvas, screen } = this.streamer;

    const { clientWidth, clientHeight } = e.target;

    const boundingRect =  canvas.getBoundingClientRect();

    const clientX = e.clientX - boundingRect.x;
    const clientY = e.clientY - boundingRect.y;

    const maxOffsetX = (clientWidth - (clientWidth / screen.zoom)) / 2;
    const maxOffsetY = (clientHeight - (clientHeight / screen.zoom)) / 2;

    const adjOffsetX = maxOffsetX - (screen.offsetX / screen.zoom);
    const adjOffsetY = maxOffsetY - (screen.offsetY / screen.zoom);

    const screenX = ((clientX / clientWidth) / screen.zoom) * clientWidth;
    const screenY = ((clientY / clientHeight) / screen.zoom) * clientHeight;

    return {
      x: clientX,
      y: clientY,
      px: ((screenX + adjOffsetX) / clientWidth) * 100,
      py: ((screenY + adjOffsetY) / clientHeight) * 100
    };
  }

  handleScroll(e) {
    const { streamer } = this;

    this.mouseMove = true;

    const newPos = this.createMousePos(e);

    const deltaX = newPos.x - this.mousePos.x;
    const deltaY = newPos.y - this.mousePos.y;

    if (!this.lockPanning) {
      this.streamer.screen.pan(deltaX, deltaY);
    }

    this.mousePos = newPos;

    this.onDrag(this.mousePos);
  }

  handleMouseDown(e) {
    this.mouseDown = true;
    this.mousePos = this.createMousePos(e);
    this.onMouseDown(this.mousePos);
  }

  handleMouseUp(e) {
    this.onMouseUp(this.mousePos);

    const click = !this.mouseMove;
    if (click) {
      this.handleClick(e)
    }

    this.mouseDown = false;
    this.mouseMove = false;
    this.mousePos = null;
  }

  handleClick(e) {
    const { streamer } = this;

    if (!streamer) return;

    const hitbox = this.findHitBox(e);
    if (hitbox) {
      hitbox.selected = true;
    } else {
      streamer.entities.selected = false;
    }

    streamer.entities.selected = hitbox || null;

    streamer.onClick(hitbox);
  }

  onKeyDown(e) {
    const {
      panLeft,
      panRight,
      panUp,
      panDown,
      center,
      toggleFullscreen,
      zoomIn,
      zoomOut
    } = this.streamer.screen;

    switch (e.key) {
      case 'a':
        this.panLeft();
        break;
      case 'ArrowLeft':
        this.panLeft();
        break;
      case 'd':
        this.panRight();
        break;
      case 'ArrowRight':
        this.panRight();
        break;
      case 'w':
        this.panUp();
        break;
      case 'ArrowUp':
        this.panUp();
        break;
      case 's':
        this.panDown();
        break;
      case 'ArrowDown':
        this.panDown();
        break;
      case 'c':
        this.center();
        break;
      case 'f':
        this.toggleFullscreen();
        break;
      case '+':
        this.zoomIn();
        break;
      case '_':
        this.zoomOut();
        break;
      default:
        // do nothing
    }
  }

  start() {
    this.listeners.forEach((listener) => {
      const { event, fn } = listener;
      this.streamer.canvas.addEventListener(event, fn);
    });

    const { screen } = this.streamer;
    const { center } = screen;

    window.addEventListener('resize', center.bind(screen));
  }

  stop() {
    this.listeners.forEach((listener) => {
      const { event, fn } = listener;
      this.streamer.canvas.removeEventListener(event, fn);
    });

    const { screen } = this.streamer;
    const { center } = screen;

    window.removeEventListener('resize', center.bind(screen));
  }
}

export default EventHandler;
