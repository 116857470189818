import Hls from 'hls.js';

import Screen from './Screen';
import Renderer from './Renderer';
import EventHandler from './EventHandler';
import Entities from './Entities';
import Annotation from './Entities/Annotation';

const FRAMES_PER_SECOND = 30;
const TEN_SECONDS = 1000 * 10;

const RESET_INTERVAL = 3000;

class Streamer {
  constructor() {
    this.resetTimeout = null; // use to check if video is stale
    this.lastFrame = null;
    this.hls = null;
    this.jsMpeg = null;

    this.frameTimestamp = null;
    this.framePrevTimestamp = null;

    this.container = null;
    this.deviceIP = null;
    this.deviceId = null;
    this.videoSrc = null;
    this.canvas = null;

    this.coverStyle = 'contain';

    this.accumulator = 0;
    this.destroyed = true;

    this.renderer = null;
    this.eventHandler = null;
    this.screen = null;
    this.entities = new Entities();

    this.highlights = [];

    this.onClick = () => {};
    this.onFatalError = () => {};
    this.onData = () => {};
    this.onFragLoaded = () => {};
    this.onDestroy = () => {};
    this.onAnnotationCreate = () => {};

    this.shouldDrawBoxes = false;
    this.shouldDrawPoses = false;
    this.shouldDrawGIds = true;
    this.shouldDrawTimestamp = false;
    this.shouldDrawAnnotations = false;

    this.canDrawIcons = false;
    this.shouldDrawMovement = true;
    this.shouldDrawHeadPos = true;

    this._addingAnnotation = false;

    this.activeId = null;
    this.selectedAnnotation = null;

    this.trackActiveId = false;

    this.listeners = [];

    this.confidence = 50;
  }

  set showIds(bool) {
    this.shouldDrawGIds = bool;
  }

  set isDebugView(bool) {
    this.shouldDrawBoxes = bool;
    this.shouldDrawPoses = bool;
    this.shouldUseCurrentWeight = bool;
    this.canDrawIcons = bool;
    this.shouldDrawTimestamp = bool;
    this.shouldDrawAnnotations = bool;
  }

  set active(id) {
    this.activeId = id;
    this.trackActiveId = !!id;
    if (!id) {
      this.center();
    }
  }

  set addingAnnotation(val) {
    this.eventHandler.lockPanning = val;
    this._addingAnnotation = val;
  }

  set activeAnnotation(val) {
    this.entities.selected = val;
  }

  removeAnnotation(id) {
    this.entities.removeAnnotation(id);
  }

  snapshot() {
    this.screen.snapshot();
  }

  zoomIn() {
    this.screen?.zoomIn();
  }

  zoomOut() {
    this.screen?.zoomOut();
  }

  center() {
    this.screen?.center();
  }

  toggleFullscreen() {
    this.screen?.toggleFullscreen();
  }

  parseFrameData(lastFrame, data) {
    if (!data?.payload?.instances) {
      return data;
    }

    return { ...data };
  }

  async startHls() {
    const url = `https://${this.deviceIP}/primary/index.m3u8`;

    if (Hls.isSupported()) {
      await new Promise((resolve, reject) => {
        this.hls = new Hls({
          enableWorker: true,
          maxBufferLength: 1,
          liveBackBufferLength: 0,
          liveSyncDuration: 1,
          liveMaxLatencyDuration: 5,
          liveDurationInfinity: true,
          highBufferWatchdogPeriod: 1,
        });

        this.hls.on(Hls.Events.ERROR, (evt, data) => {
          if (!data.fatal) return;

          this.hls.destroy();

          this.onFatalError();
          reject('could not start stream');
        });

        this.hls.on(Hls.Events.MANIFEST_LOADED, () => {
          resolve();
        });

        this.hls.on(Hls.Events.FRAG_LOADED, () => {
          this.onFragLoaded();
          clearTimeout(this.resetTimeout);
          this.resetTimeout = setTimeout(() => {
            this.onFatalError();
          }, RESET_INTERVAL);
        });

        this.onDestroy = () => {
          resolve();
        };

        this.hls.loadSource(url);
        this.hls.attachMedia(this.videoSrc);
      });
    } else if (this.videoSrc.canPlayType('application/vnd.apple.mpegurl')) {
      // since it's not possible to detect timeout errors in iOS,
      // wait for the playlist to be available before starting the stream
      await fetch(url);

      this.videoSrc.src = url;
    }

    return true;
  }

  startSockets(socketClient, deviceId) {
    socketClient.subscribe(`device-frame-meta`, deviceId, (data) => {
      if (this.destroyed) return;

      this.onData(data);

      const parsedFrameData = this.parseFrameData(this.lastFrame, data);

      this.entities.clearCattle();
      this.entities.registerCattle(parsedFrameData?.payload);

      this.lastFrame = parsedFrameData;
      this.framePrevTimestamp = this.frameTimestamp;
      this.frameTimestamp = new Date().getTime();
    });
  }

  stopSockets(socketClient, deviceId) {
    socketClient.unsubscribe('device-frame-meta', deviceId);
  }

  onFrameAnimation(timestamp) {
    if (this.destroyed) {
      return;
    }

    const delta = timestamp - this.prevTimestamp;
    this.prevTimestamp = timestamp;

    this.accumulator += delta;

    if (!this.renderer || this.accumulator < 1000 / FRAMES_PER_SECOND) {
      window.requestAnimationFrame(this.onFrameAnimation.bind(this));
      return;
    }

    const showCattle = Date.now() - this.frameTimestamp <= TEN_SECONDS;
    if (!showCattle) {
      this.entities.clearCattle();
    }

    this.accumulator = 0;

    const payload = this.lastFrame?.payload;

    const renderList = [];

    const frameCoordToCanvasFn = this.screen.frameCoordToCanvas(this.lastFrame);
    const percToCanvasFn = this.screen.percToCanvas();

    this.renderer.clear(this.canvas);
    this.renderer.render(
      this.canvas,
      this.frameTimestamp,
      this.framePrevTimestamp,
      frameCoordToCanvasFn,
      percToCanvasFn
    );

    this.screen.zoomOnActiveId(payload);

    window.requestAnimationFrame(this.onFrameAnimation.bind(this));
  }

  async start(deviceIP, deviceId, container, videoSrc, canvas, annotations = []) {
    this.container = container;
    this.deviceIP = deviceIP;
    this.deviceId = deviceId;
    this.videoSrc = videoSrc;
    this.canvas = canvas;
    this.entities.clearAnnotations();
    this.destroyed = false;
    this.highlights = [];
    this.screen = new Screen(this);
    this.renderer = new Renderer(this);
    this.eventHandler = new EventHandler(this);
    this.eventHandler.start();

    this.entities.registerAnnotations(annotations);

    let started = false;
    try {
      started = await this.startHls();
      await this.videoSrc.play();
    } catch (err) {
      return false;
    }

    if (!started) {
      return false;
    }

    // register event handler events
    this.eventHandler.onMouseDown = (e) => {
      if (this._addingAnnotation) {
        this.entities.entity = new Annotation({
          x1: e.px,
          y1: e.py,
          x2: e.px,
          y2: e.py
        });
      }
    };

    this.eventHandler.onDrag = (e) => {
      if (this._addingAnnotation) {
        const newAnnotation = this.entities.entity;
        newAnnotation.box.endX = e.px;
        newAnnotation.box.endY = e.py;
      }
    };

    this.eventHandler.onMouseUp = (e) => {
      if (this._addingAnnotation) {
        const newAnnotation = this.entities.entity;
        this.entities.entity = null;

        newAnnotation.box.endX = e.px;
        newAnnotation.box.endY = e.py;

        const { startX, startY, endX, endY } = newAnnotation.box;

        this.entities.addAnnotation(newAnnotation);

        this.onAnnotationCreate({
          id: newAnnotation.id,
          x1: startX, y1: startY,
          x2: endX, y2: endY,
          device_id: this.deviceId
        });
      }
    };

    this.listeners.forEach((listener) => {
      const { event, fn } = listener;
      this.videoSrc.addEventListener(event, fn);
    });

    window.requestAnimationFrame(this.onFrameAnimation.bind(this));

    return true;
  }

  stop() {
    this.destroyed = true;
    this.deviceId = null;
    this.highlights = [];

    this.renderer.clear(this.canvas);
    this.lastFrame = null;
    this.renderer = null;

    this.eventHandler.stop();
    this.eventHandler = null;

    this.screen = null;

    if (this.hls) {
      this.hls.destroy();
      this.onDestroy();
      this.hls = null;
    }

    this.listeners.forEach((listener) => {
      const { event, fn } = listener;
      this.videoSrc.removeEventListener(event, fn);
    });
  }
}

export default Streamer;
