class AnnotationRenderer {
  static render(ctx, streamer, annotations) {
    this.drawBoxes(ctx, annotations);
    this.fillBoxes(ctx, streamer, annotations);
  }

  static drawBoxes(ctx, annotations) {
    ctx.beginPath();

    for (const annotation of annotations) {
      annotation.drawBox(ctx);
    }

    ctx.strokeStyle = '#fe0054';
    ctx.lineWidth = 1;
    ctx.stroke();
  }

  static fillBoxes(ctx, streamer, annotations) {
    for (const a of annotations) {
      const hovered = a.id === streamer.entities.hovered?.id;
      const selected = a.id === streamer.entities.selected?.id;

      if (hovered) {
        ctx.beginPath();

        a.fillBox(ctx);

        ctx.fillStyle = '#a003';
        ctx.lineWidth = 1;
        ctx.fill();
      } else if (selected) {
        ctx.beginPath();

        a.fillBox(ctx);

        ctx.fillStyle = '#f003';
        ctx.lineWidth = 1;
        ctx.fill();
      }
    }
  }
}

export default AnnotationRenderer;
