import React from 'react'

import css from './styles.module.scss'
import PulseLoader from 'react-spinners/PulseLoader';

const Loader = (props) => {
  const { loading, children } = props;

  if (!loading) {
    return children;
  }

  return (
    <div style={{ flex: 1 }}>
        <PulseLoader
          color="#C4C4C4"
          loading={true}
          cssOverride={{
            height: 60,
            margin: 'auto',
            width: 60,
          }}
          size={8}
          aria-label="Loading dots"
          data-testid="loader"
        />
    </div>
  )
}
export default Loader